<template>
  <div class="content">
    <div class="main details">
      <el-row>
        <div
          v-for="(item, index) of list"
          :key="index"
          :class="index > 2 ? 'min_col' : ''"
        >
          <el-col :span="24" v-if="index == 0">
            <div class="item item_one">
              <div class="imgView">
                <img :src="item.img" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <time>{{ item.year }}</time>
                  <span>{{ item.keywords }}</span>
                </div>
                <h3>{{ item.title }}</h3>
                <div class="txtCon">{{ item.description }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-else-if="index == 1">
            <div class="item item_two">
              <div class="imgView">
                <img :src="item.img" alt="" />
              </div>
              <div class="details_txt">
                {{ item.description }}
              </div>
            </div>
          </el-col>
          <el-col :span="16" v-else-if="index == 2" class="dr">
            <div class="item item_three">
              <div class="imgView">
                <img :src="item.img" alt="" />
              </div>
              <div class="details_txt">
                {{ item.description }}
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-else>
            <div class="item item_four">
              <div class="imgView">
                <img :src="item.img" alt="" />
              </div>
              <div class="details_txt">
                {{ item.description }}
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    <div class="subscribeNewsView">
      <subscribe-news />
    </div>
  </div>
</template>

<script>
import subscribeNews from "@/components/subscribeNews";
export default {
  name: "News",
  components: {
    subscribeNews,
  },
  data() {
    return {
      id: this.$route.query.id,
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.newsDetail({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.content;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/news.scss";
</style>